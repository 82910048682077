@mixin mobile($media) {
  @if $media=="xs" {

      @include media-breakpoint-only(xs) {
          @content;
      }

  }

  @else if $media=="sm" {

      @include media-breakpoint-only(sm) {
          @content;
      }

  }

  @else if $media=="md" {

      @include media-breakpoint-only(md) {
          @content;
      }

  }

  @else if $media=="lg" {

      @include media-breakpoint-only(lg) {
          @content;
      }

  }

  @else if $media=="xl" {

      @include media-breakpoint-only(xl) {
          @content;
      }

  }

  @else if $media=="xxl" {

      @include media-breakpoint-only(xxl) {
          @content;
      }

  }

  @else if $media=="xxxl" {

      @include media-breakpoint-only(xxxl) {
          @content;
      }

  }

  @else if $media=="sm-x" {

      @include media-breakpoint-up(sm) {
          @content;
      }

  }

  @else if $media=="md-x" {

      @include media-breakpoint-up(md) {
          @content;
      }

  }

  @else if $media=="lg-x" {

      @include media-breakpoint-up(lg) {
          @content;
      }

  }

  @else if $media=="xl-x" {

      @include media-breakpoint-up(xl) {
          @content;
      }

  }

  @else if $media=="xxl-x" {

      @include media-breakpoint-up(xxl) {
          @content;
      }

  }

  @else if $media=="x-sm" {

      @include media-breakpoint-down(md) {
          @content;
      }

  }

  @else if $media=="x-md" {

      @include media-breakpoint-down(lg) {
          @content;
      }

  }

  @else if $media=="x-lg" {

      @include media-breakpoint-down(xl) {
          @content;
      }

  }

  @else if $media=="x-xl" {

      @include media-breakpoint-down(xxl) {
          @content;
      }

  }

  @else if $media=="x-xxl" {

      @include media-breakpoint-down(xxxl) {
          @content;
      }

  }

  @else if $media=="sm-md" {

      @include media-breakpoint-between(sm, md) {
          @content;
      }

  }

  @else if $media=="sm-lg" {

      @include media-breakpoint-between(sm, lg) {
          @content;
      }

  }

  @else if $media=="sm-xl" {

      @include media-breakpoint-between(sm, xl) {
          @content;
      }

  }

  @else if $media=="sm-xxl" {

      @include media-breakpoint-between(sm, xxl) {
          @content;
      }

  }

  @else if $media=="md-lg" {

      @include media-breakpoint-between(md, lg) {
          @content;
      }

  }

  @else if $media=="md-xl" {

      @include media-breakpoint-between(md, xl) {
          @content;
      }

  }

  @else if $media=="md-xxl" {

      @include media-breakpoint-between(md, xxl) {
          @content;
      }

  }

  @else if $media=="lg-xl" {

      @include media-breakpoint-between(lg, xl) {
          @content;
      }

  }

  @else if $media=="lg-xxl" {

      @include media-breakpoint-between(lg, xxl) {
          @content;
      }

  }
}

@mixin placeholder($color: $input-color-placeholder) {

  // Firefox
  &::-moz-placeholder {
      color: $color;
      opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  &:-ms-input-placeholder {
      color: $color;
  }

  // Internet Explorer 10+
  &::-webkit-input-placeholder {
      color: $color;
  }

  // Safari and Chrome
}


//BLST MININS

//Font Mixins
@mixin f-default(
  $size: $f-size-h6,
  $weight: 400,
  $color: $color-15,
  $text-transform: none,
  $font-family: inherit) {
  text-decoration: $text-transform;
  font-size: $size;
  font-weight: $weight;
  // color: $color;
  text-transform: $text-transform;
  font-family: $font-family;
}

@mixin img-wrap($width: 100px, $height: 100px) {
  width: $width;
  height: $height;
  overflow: hidden;

  img {
      width: $width;
      height: $height;
      object-fit: cover;
      transition: all 0.3s;
  }
}

//Link Animation
@mixin default-link-animation($f-size: $f-size-h6, $color: $color-6) {
  a {
      display: inline-block;
      position: relative;
      text-decoration: none;
      padding-left: 0;
      padding-right: 0;
      font-weight: 400;
      color: $color;

      font-size: $f-size;

      &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          transform: scaleX(0);
          background-color: $color;
          transition: transform 0.3s;
      }

      &:hover::after {
          transform: scaleX(1);
      }
  }
}
