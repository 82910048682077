::-moz-selection {
  color: #fff;
  background: lighten($primary, 5%);
}

::selection {
  color: #fff;
  background: lighten($primary, 5%);
}

:root {
  --bs-body-color: #555;
}

html {
  font-size: 16px;
  position: relative;

  @include mobile(x-xl) {
      font-size: 14px;
  }
}

body {
position: relative;
  font-size: 1rem;
  font-weight: 400;

  @include mobile(x-md) {
      font-size: 0.9375rem;
  }

  &.menu-open {
      overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

a {

  text-decoration: none;
  transition: all .2s ease-in-out;
-moz-transition: all .2s ease-in-out;
-webkit-transition: all .2s ease-in-out;

}

h1 {
  color: $color-4;
  @include f-default($f-size-h1);

  @include mobile(lg) {
      font-size: math.div(55, 16) + rem;
  }

  @include mobile(x-md) {
      font-size: math.div(38, 16) + rem;
  }
}

h2 {
  color: $color-4;
  @include f-default($f-size-h2);

  @include mobile(lg) {
      font-size: math.div(38, 16) + rem;
  }

  @include mobile(md) {
      font-size: math.div(32, 16) + rem;
  }
}

h3 {
  color: $color-4;
  @include f-default($f-size-h3);

  @include mobile(lg) {
      font-size: math.div(28, 16) + rem;
  }

  @include mobile(x-md) {
      font-size: math.div(24, 16) + rem;
  }
}

h4 {
  @include f-default($f-size-h4);

  @include mobile(lg) {
      font-size: math.div(25, 16) + rem;
  }

  @include mobile(x-md) {
      font-size: math.div(22, 16) + rem;
  }
}

h5 {
  @include f-default($f-size-h5);

  @include mobile(lg) {
      font-size: math.div(20, 16) + rem;
  }

  @include mobile(x-md) {
      font-size: math.div(19, 16) + rem;
  }
}

h6 {
  @include f-default($f-size-h6);

  @include mobile(x-md) {
      font-size: math.div(17, 16) + rem;
      margin-bottom: 0.5rem;
  }
}

p {
  color: #555;

  @include mobile(x-md) {
      margin: 0 0 1.5rem;
      line-height: 1.5rem;
  }

  &:last-child {
      margin: 0;
  }

  &:empty {
      display: none;
  }
}

.btn-close {
  --bs-btn-close-focus-shadow: none;
}

.btn-light {
  background-color: transparent;
  color: $color-13;
  border: 1px solid $color-13;

  &:hover {
      background-color: $color-13;
      color: $color-1;
  }

  &:focus {
      box-shadow: none;
      border: none;
  }
}

.border-section {
  .border {
      border: 2px solid $color-5;
  }
}

.top-filter-dropdowns {
	display: flex;
	justify-content: space-between;

	@include mobile("x-md") {
		flex-direction: column;
	}

	.content-dropdown-wrap {
		display: flex;
		border-radius: 5px;
		box-shadow: 0 2px 30px rgba(0,0,0,.15);

		@include mobile("x-md") {
			flex-direction: column;
			border: unset;
			align-items: center;
			box-shadow: unset;
		}
    a {
      text-decoration: none;
    }
		.map-dropdown-wrap {
			font-size: 1.125rem;
			width: 300px;
			padding: .7rem 2.5rem .7rem 1rem;
			color: $color-10;
			display: flex;
			align-items: center;
			text-align: start;

			@include mobile("x-md") {
				border: 1px solid #d7dde4;
			}

			i {
				display: inline-block;
				color: $primary;
				font-size: 18px;
				margin-right: 10px;
				opacity: .6;
				transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-webkit-transition: all .2s ease-in-out;
			}

			&:hover {
				i {
					opacity: 1;
				}
			}
		}

		.fs-dropdown-wrap {
			width: 300px;
      border-left: 1px solid #d7dde4;

			@include mobile("x-md") {
				padding-bottom: 10px;
				padding-top: 10px;
			}

			.form-select {
				padding: .7rem 2.5rem .7rem 1rem;
				border-color: transparent;
				border-radius: 0;
			}

			.fs-wrap {
				/* &.fs-open {
					.fs-label-wrap {
						background-color: $color-3 !important;

						.fs-label {
							color: $color-1;
						}

						.fs-arrow {
							background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
						}
					}
				} */

				.fs-label-wrap {
					border-radius: 0;
					border: none;
					border-left: 1px solid #d7dde4;
					background-color: transparent;

					@include mobile("x-md") {
						border: 1px solid #d7dde4;
					}

					.fs-label {
						color: $color-10;
						border-radius: 0;
						padding: .7rem 2.5rem .7rem 1rem;
					}
				}

				.fs-dropdown {
					.fs-options {
						.fs-option {
							&.selected {
								background-color: $color-3;
							}
						}
					}
				}
			}
		}
	}
}

.bottom-line {
  position: relative;
  margin-bottom: 20px;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust the thickness of the line */
    background-color: #000; /* Adjust the color of the line */
  }
}

.download-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  @include mobile("x-md") {
    flex-direction: column;
    align-items: center;
  }

  .download-pdf {
    @include mobile("x-md") {
      margin-bottom: 20px;
    }
  }

  a {
    &.button-pdf {
      font-weight: 700;
      display: inline-block;
      padding: 10px 20px;
      text-decoration: none;
      background-color: #EEEEEE; 
      color: #000; 
      border: 1px solid #000; 
      border-radius: 4px; 
      transition: background-color 0.3s;
    }
    &.button-image {
      font-weight: 700;
      display: inline-block;
      padding: 10px 20px;
      text-decoration: none;
      background-color: #fff; 
      color: #66A0C3; 
      border: 1px solid #000; 
      border-radius: 4px;
      transition: background-color 0.3s;
    }  
  }
}

.floating-buttons {
  position: fixed;
  top: 125px;
  right:0;
  z-index: 1000;

  .buttons-wrap {
      border: 1px solid #ced4da;
      border-radius: 15px;
      .faq {
          padding: 10px 10px;
      }
      .setting-border {
         border-bottom: 1px solid #ced4da;
         display: block;
      }
      .feedback {
          padding: 10px 10px;
      }
  } 
}

#registerModal, #feedbackModal {
  z-index: 2000;
  .modal-header {
      h1 {
          font-size: 2.25rem;
          font-weight: 900;
      }
  }
  p {
      font-size: 1.125rem;
  }
  .form-group {
      margin-bottom: 1.25rem;

      &:last-child {
          margin-bottom: 0;
      }
  }
  .modal-footer {
      padding: 20px 40px;
      .red {
          color: red;
      }
  }

}

@keyframes skeleton-loading {
  0% {
    background-color: #a3b8c2ad;
  }
  100% {
    background-color: #f0f3f536;
  }
}