.index-page {
  margin-top: 100px;

  .inner-wrapper-header {
    padding: 50px 0;
    h2 {
      color: $color-4;
      font-size: 2.5rem;
      @include mobile("x-md") {
        font-size: calc(1.35rem + 1.2vw);
      }
    }
    @include mobile("x-md") {
      padding: 10px;
    }

    .inner-header-wrap {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
      @include mobile("x-md") {
        max-width: 100%;
      }
    }
  }

  .card-wrap {
    padding-bottom: 50px;
    .card {
      width: 350px;
      border: none;
      @include mobile("x-md") {
        width: 100%;
      }
      .img-wrapper {
        position: relative;
        overflow: hidden;
    
        img {
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
        }
    
        &:hover img {
            transform: scale(1.1);
        }
    }
    }
  }
}