.sign-up-wrapper{
    height: 80vh;

    .image-wrapper{
        img{
            height: 80vh;
            width: 100%;
            object-fit: cover;
        }
    }

    .nav-link{
        color: #ffffff;
        background-color: #113f678d;
        &.active{
            background-color: #113f67 !important;
            color: #ffffff;
        }

        &:hover{
            color: #000;
        }
    }

    .tab-main-wrapper{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-pills{
            display: flex;
            gap: 80px;
        }

        

        .tab-wrapper{
            max-width: 455px;
        }

        .tab-pane{
            min-height: 300px;
        }
    }
}