.page-content {
  &.error404-page {
    display: flex;
    align-items: center;
    height: calc(100vh - 300px);

    .action {
        display: flex;
        justify-content: center;

      .link {
        background-color: #fff;
        border: 1px solid #fff;
        padding: 8px 25px;
        text-decoration: none;
        font-weight: 700;
        border-radius: 8px;
        transition: all 0.3s;

        &:hover {
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }
}
