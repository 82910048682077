$fa-font-path: "./assets/fonts/font-awesome-pro" !default;
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@200;300;400;500;600;700;800;900;1000&display=swap");
/* Slick Slider Variables ---------------------------------------------------------------------- */

$slick-font-path: "../fonts/slick/";
$slick-font-family: "";
$slick-loader-path: "../imgs/slick/";

/* Bootstrap Variables ---------------------------------------------------------------------- */

$font-family-sans-serif: 'Lato',
	sans-serif;
$font-size-base: 1rem;
// $line-height-base: 1.6;
$headings-font-family: 'Lato',
	sans-serif;
$headings-font-weight: 600;
// $headings-line-height: 1.2;

$h1-font-size: 8.438rem;
$h2-font-size: 2.25rem;
$h3-font-size: 2.25rem;
$h4-font-size: 1.625rem;
$h5-font-size: 1.2rem;
$h6-font-size: 1.101rem;

// Theme Colors
$link-color: #00000056;

$body-bg: #fff;

$primary: #1F628B;
$secondary: #BADA55;
$success: #13B128;
$danger: #F14336;
$info: #1976D2;
$dark: #1a1a1a;
$gray-900: #0000009C;
$gray-500: #f1f0f5;

$border: #9e9e9e;
$meta: #F8FAF8;

$grid-gutter-width: 1.5rem;
$spacer: 1rem;
$gutters: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 2,
	6: $spacer * 3,
);

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer * 1.75,
	4: $spacer * 2.75,
	5: $spacer * 3.75,
	6: $spacer * 1.875,
	7: $spacer,
);

$navbar-light-color: #555;
$navbar-light-hover-color: $primary;

$border-radius-sm: 5px;
$border-radius: 5px;
$border-radius-lg: 15px;

// Input / Button
$input-btn-line-height: 1.5;

$input-btn-height: 40px;
$input-btn-font-size: 1rem;
$input-btn-padding-y: math.div((math.div(($input-btn-height - 2px), 16px)) - ($input-btn-font-size * $input-btn-line-height), 2); //.96875rem;
$input-btn-padding-x: 1rem;

$input-btn-height-lg: 70px;
$input-btn-font-size-lg: 1.125rem;
$input-btn-padding-y-lg: math.div((math.div(($input-btn-height-lg - 2px), 16px)) - ($input-btn-font-size-lg * $input-btn-line-height), 2); //1.28125rem;
$input-btn-padding-x-lg: 2rem;

// Input
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-border-color: #4040405a;
$input-box-shadow: none;
$input-focus-border-color: #40404080;
$input-focus-box-shadow: none;
$form-select-indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36' height='36'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
$form-check-input-border-radius: .15em;

$input-color: #1A1A1A;

// Select
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;

$form-select-border-radius: $input-border-radius;
$form-select-border-radius-sm: $input-border-radius-sm;
$form-select-border-radius-lg: $input-border-radius-lg;

$form-select-indicator-padding: $form-select-padding-x * 2;
$form-select-bg-position: right ($form-select-padding-x - 0.5rem) center;
$form-select-bg-size: 20px 20px;
$form-select-indicator-color: $primary;

//input fields
$form-control-width: 100%;
$form-control-padding-y: 10px;
$form-control-padding-x: 10px;
$form-control-border: 1px solid #0000004F;


// Button
$btn-font-size: $input-btn-font-size;
$btn-font-weight: 400;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;
/* $btn-hover-bg-shade-amount: 100%;
$btn-hover-bg-tint-amount: 100%;
$btn-hover-color : #404040; */

//Font Weight
$font-weight-bolder: 900;


//Offcanvace
$offcanvas-padding-y: 3.6rem;
$offcanvas-padding-x: 3.6rem;

//Shadow
$box-shadow-sm: 0 .135rem 0.35rem rgba(#000, .25);

//Model
$modal-inner-padding: 20px 40px;
$modal-backdrop-opacity: .6;
$zindex-modal: 2055;
$zindex-modal-backdrop: 2050;


//End Bootstrap Variables



//Colors
$color-1: #FFFFFF;
$color-2: #f7902b;
$color-3: #BADA55;
$color-4: #14425E;
$color-5: #777777;
$color-6: #1D1D1F;
$color-7: #3A3A3A;
$color-8: #1A1A1A;
$color-9: #1e628c;
$color-10: #66A0C3;
$color-11: #4E84A6;
$color-12: #D5D5D5;
$color-13: #1d1a1a;
$color-14: #e4e4e4;
$color-15: #686767;
$color-16: #787575;

//Font Sizes
$f-size-h1: 3.75rem;
$f-size-h2: 2.5rem ;
$f-size-h3: 2rem;
$f-size-h4: 1.5rem;
$f-size-h5: 1.25rem;
$f-size-h6: 1.125rem;