.top-footer {
	.note-text {
		margin: 0 0 30px;
		
		@include mobile(x-md) {
			padding: 0 40px;
		}
	}
}

.footer {
	position: relative;
	display: flex;
	color: #fff;
	font-size: 0.875rem;
	font-weight: 300;
	padding: 50px 0 34px;
	background-color: #24618c;
	margin-top: 0.625rem;
	margin-top: auto;
	min-height: 450px;

	@include mobile(x-md) {
		font-size: 0.75rem;
		padding: 30px 20px 20px;
	}

	h1, h2, h3, h4, h5, p, a {
		color: #fff;
	}
	a {
		text-decoration: none;
	}
	h5 {
		font-size: 1.25rem;
		font-weight: 300;
		margin: 0;
	}

	strong {
		font-weight: 700;
	}

	.nav {
		--bs-nav-link-color: #fff;
    	--bs-nav-link-hover-color: #BADA55;
		--bs-nav-link-padding-x: 0;
		--bs-nav-link-padding-y: 0.3rem;

		.dropdown {
			display: flex;
			align-items: center;
			
			&:hover {
				.menu-dropdown {
					display: flex;
				}
			}

			> a {
				display: inline-block;
				min-width: 125px;
			}

			.menu-dropdown {
				position: relative;
				display: none;
				align-items: center;

				li:not(:last-child) {
					padding-right: 10px;
					margin-right: 10px;
					border-right: 1px solid #6a8da9;
				}
				
				.dropdown-item {
					color: #fff;
					font-weight: 300;
					display: flex;
					align-items: center;
					line-height: 1.2;

					&:hover {
						color: #BADA55;
					}
				}
			}
		}
	}

	.newslatter {
		font-size: 0.875rem;

		@include mobile(x-md) {
			font-size: 1rem;
		}
	
	}

	.scial-media {
		margin-bottom: 40px;

		@include mobile(x-md) {
			margin-bottom: 20px;
		}

		a {
			text-decoration: none;
			display: inline-block;
			margin: 0 7px;

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
