.event-hero{
    padding-top: 15px;
    .top-nav{
      ul{
        padding-left: 0;
        display: flex;
        gap: 22px;
        li{
          list-style: none;

          a{
            text-decoration: none;
            color: $color-6;
            opacity: .7;
          }
        }
      }
    }
   
    .about-product{
      padding-top: 40px;
    }
    .event-images{
      .grid-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;

        @include media-breakpoint-down(md){
          
          grid-template-columns: repeat(1, 1fr);

          img{
            width: 100%;
            height: 240px !important;
          }
        }
        .single-col{
          img{
            object-fit: cover;
          }
          &.second-col{
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            row-gap: 15px;

            .img-wrapper{
              img{
                height: 240px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .overview{
    font-size: 24px;
    padding-top: 30px;
  }
  .sub-header{
    font-size: 20px;
    padding-top: 6px;
    padding-bottom: 10px;
  }
  .content-wrap{
    .main-title{
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
  .product-images {
    .owl-big { 
      .item {
        background: #0c83e7;
        color: #ffffff;
        text-align: center;
        margin: 5px;
        padding: 0px 0px;
      }
      
      &.owl-theme {
        position: relative;
        
        .owl-next,
        .owl-prev {
          position: absolute;
          top: 50%;
          width: 22px;
          height: 40px;
          margin-top: -20px;
        }
        
        .owl-prev {
          left: 10px;
        }
        
        .owl-next {
          right: 10px;
        }
       }
    }
    
    .owl-small {
      .item {
        background: #c9c9c9;
        color: #fff;
        text-align: center;  
        cursor: pointer;
        margin: 5px;
        padding: 0px 0px;
      }
      .owl-nav {
        text-align: center;
      }
      .current {
        .item {
          background: red;
        }
      }
    }
    
  }

.reviews{
  .title{
    font-size: 24px;
  padding-top: 30px;
  }

  .review-wrapper{
    .single-review{
      padding-top: 15px;
      border-bottom: 1px solid #00000049;
      padding-bottom: 12px;

      &:last-child{
        border: none;
      }
        .user{
          display: flex;
          align-items: center;
          gap: 15px;

          img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          
          .details-wrap{
            .name{
              margin-bottom: 0;
              font-size: 16px;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .describe{
          
          .title{
            padding-top: 12px;
          }
        }
        .description{
          font-weight: 400;
          font-size: 16px;
        }
    }
  }
}

.more-to-explore{
  .title{
    font-size: 28px;
  }
  .card-title{
    font-size: 18px;
  }
  .features{
    display: flex;
    gap: 12px;
    padding-top: 6px;

    .single{
      display: flex;
      align-items: flex-end;
      gap: 4px;
    }
  }
}

.map-wrapper{
  padding-top: 35px;
  .title{
    font-size: 28px;
    padding-bottom: 8px;
  }

  .map-wrap{
    iframe{

      background-color: #00000032;
    }
  }
}
