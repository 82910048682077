.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2000;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

    .navbar-brand {
        img {
            width: auto;
            height: 58px;

            @include mobile(x-md) {
                height: 45px;
            }
        }
    }
    
    .navbar {
        --bs-navbar-color: rgba(0, 0, 0, 0.7);
        --bs-navbar-hover-color: #3d9dab;
        --bs-navbar-padding-y: 1.075rem;
        --bs-navbar-toggler-padding-x: 0.5rem;
        --bs-navbar-toggler-focus-width: 0;
        --bs-navbar-toggler-border-color: rgba(0, 0, 0, .2);
        --bs-navbar-nav-link-padding-x: 1.6rem;

        @include mobile(x-md) {
            --bs-navbar-padding-y: .6rem;
        }
    }

    .navbar-nav {
        --bs-nav-link-font-size: 0.875rem;

        @include mobile(x-lg) {
            margin: 10px 0 0;
            border-top: 1px solid #ccc;
            padding: 10px 10px 0;
        }
        @include mobile(xl-x) {
            --bs-nav-link-padding-y: 1.5rem;
        }

        li {
            @media (hover: hover) {
                &:hover {
                    .dropdown-menu {
                        display: block;
                    }
                }
            }

            .nav-link {
                padding-bottom: 0.3rem;
            }

            .dropdown-menu {
                --bs-dropdown-color: rgba(0, 0, 0, 0.7);
                --bs-dropdown-font-size: 0.875rem;
                --bs-dropdown-padding-y: 1rem;
                --bs-dropdown-item-padding-x:  1rem;
                --bs-dropdown-item-padding-y: .6rem;
                --bs-dropdown-link-color: rgba(0, 0, 0, 0.7);
                --bs-dropdown-link-hover-color: #3d9dab;
                --bs-dropdown-link-hover-bg: #fff;
                --bs-dropdown-link-active-color:  #3d9dab;
                --bs-dropdown-link-active-bg:  #fff;
                --bs-dropdown-border-width:  0;
                margin: 0;
                
                @include mobile(x-lg) {
                    display: block;
                }
                @include mobile(xl-x) {
                    //box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
                }
                
            }
        }
    }

}
